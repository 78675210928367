<template>
	<div id="recommend">

		<div class=" w-100 d-flex justify-content-center">
			<img class="banner-img w-100 h-100" :src="$imgURL + pageBanner" alt="" />
		</div>
		<!-- 九大课程列表 -->
		<div class="d-flex justify-content-center">
			<div class="rec-wrap container  py-2">
				<div class="home-title mb-1 mb-sm-2">
					<div class="font-md text-center">{{ configs.overview_name }}</div>
					<div class="font text-center font-line">{{ configs.overview_eng }}</div>
					<div class="font text-center">{{ configs.overview_desc }}</div>


				</div>


				<div class="d-flex flex-column p-0-5 p-md-0">
					<div class="d-flex flex-row flex-wrap">
						<div class="kecheng-p col-12 col-md-6" v-for="(item,index) in overviews" v-if="index<=5" :key="index">
							<div class=" p-0  rounded-ssm  d-flex flex-row">
								<div class="col-5   p-0  " v-if="index%2==0">

									<el-carousel :loop="true" :height="isSmall?'160px':'226px'" arrow="nerver" indicator-position="none">
										<el-carousel-item v-for="item1 in item.images" :key="item1">
											<div class="d-flex justify-content-center h-100">
												<img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
											</div>
										</el-carousel-item>
									</el-carousel>
								</div>
								<div class="col-7  bg-kecheng d-flex  px-1 flex-column justify-content-center " :class="[index==1||index==2||index==5||(index==4 && isSmall)?'text-white':'',index==1||(index==5 && !isSmall)||(index==4 && isSmall)?'bg-yellow1':'',index==2||(index==5&&isSmall)?'bg-blue':'',index%2==0?'':' text-right']">
									<div class="font-smd  font-weight-bold">{{item.title}}</div>
									<div class="font mt-0-5">{{item.desc}}</div>
								</div>



								<div class="col-5  p-0 " v-if="index%2!=0">

									<el-carousel :height="isSmall?'160px':'226px'" arrow="nerver" indicator-position="none">
										<el-carousel-item v-for="item1 in item.images" :key="item1">
											<div class="d-flex justify-content-center h-100">
												<img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
											</div>
										</el-carousel-item>
									</el-carousel>
								</div>
							</div>

						</div>
					</div>
					<div class="d-flex flex-column flex-md-row  " style="margin-top: 0.08rem;">
						<div class="col-12 col-md-7  p-0 d-flex flex-column">
							<div class="kecheng-p" v-for="(item,index) in overviews" :key="index" v-if="index>=6&&index<8">
								<div class="d-flex border-kecheng  flex-row rounded-ssm">
									<div class="col-6 px-1  " v-if="index==7&&isSmall">
										<div class="   h-100 d-flex flex-column justify-content-center ">
											<div class="font-smd font-weight-bold">{{item.title}}</div>
											<div class="font mt-0-5">{{item.desc}}</div>
										</div>
									</div>
									<div class="col-6 p-0">

										<el-carousel direction="vertical" :height="isSmall?'160px':'286px'" arrow="nerver" indicator-position="none">
											<el-carousel-item v-for="item1 in item.images" :key="item1">
												<div class="d-flex justify-content-center h-100">
													<img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
												</div>
											</el-carousel-item>
										</el-carousel>
									</div>
									<div class="col-6 px-1 " :class="index==6 && isSmall?'d-block':'d-block'">
										<div class="   h-100 d-flex flex-column justify-content-center ">
											<div class="font-smd font-weight-bold">{{item.title}}</div>
											<div class="font mt-0-5">{{item.desc}}</div>
										</div>
									</div>

								</div>

							</div>


						</div>
						<div class="col-12 col-md-5   p-0  ">
							<div class="kecheng-p ">
								<div class=" d-flex flex-row border-kecheng rounded-ssm">
									<div class="col-6 col-sm-7 p-0 ">
										<el-carousel loop='true' direction="vertical" :height="isSmall?'160px':'590px'" arrow="nerver"
										 indicator-position="none">
											<el-carousel-item class="h-100" v-for="item1 in overviews[8].images" :key="item1">
												<div class="d-flex justify-content-center h-100">
													<img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
												</div>
											</el-carousel-item>
										</el-carousel>
									</div>
									<div class="col-6 col-sm-5 px-1 py-0 d-flex align-items-sm-center justify-content-sm-end justify-content-center pb-0  pb-sm-2 flex-column ">
										<div class=" w-100px   d-none d-sm-block">
											<div class="font-smd font-weight-bold">{{overviews[8].title.split(',')[0]}}</div>
											<div class="font-smd  font-weight-bold">{{overviews[8].title.split(',')[1]}}</div>
										</div>
										<div class="    d-block d-sm-none">
											<div class="font-smd font-weight-bold">{{overviews[8].title.split(',')[0]}}{{overviews[8].title.split(',')[1]}}</div>

										</div>

										<div class="font  mt-0-5 w-100px">{{overviews[8].desc}}</div>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>

				<!-- <div class="content px-0-5 px-sm-0">
					<div class="item" v-for="(item, index) in overviews" :key="index">
						<div class="top-wrap ">
							<div class="top font-smd px-1 px-sm-2 py-0-5">{{ item.title }}</div>
						</div>
						<div class="round mt-0-5 mt-lg-0 bg-light px-1 px-sm-2  py-0-5 py-sm-1  font d-flex align-items-center flex-row">
							<div v-if="index%2!=0" class="b0t-t2 font-md col-1  d-flex justify-content-center ">0{{ index + 1 }}</div>
							<div class="col-11 ">{{ item.desc }}</div>
							<div v-if="index%2==0" class="b0t-t2 font-md col-1  d-flex justify-content-center ">0{{ index + 1 }}</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>


		<!-- 封面 -->


		<div class="d-flex  cover flex-column flex-lg-row container-fluid">
			<div class="h-8   w-600">
				<div class="left-wrap  pb-2 pt-2 pt-lg-4" id='yunduan'>
					<h5>{{ configs.class_title1 }}</h5>

					<h3>{{ configs.class_title2 }}</h3>
					<h2>{{ configs.class_title3 }}</h2>
					<h4>{{ configs.class_title4 }}</h4>
					<div class="desc font px-1 px-sm-3">
						{{configs.class_content}}
					</div>
				</div>
			</div>
			<div class=" h-8  d-none d-lg-block" :style="{width:windowWidth-550+'px'}">
				<el-carousel indicator-position="none" height="8rem" style="height: 100%;">
					<el-carousel-item class="h-100" v-for="item in configs.class_images" :key="item">
						<img v-if="configs.class_images" class="cover-img" :src="$imgURL+item" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="col-12 col-lg-8 p-0 d-block d-lg-none">
				<el-carousel indicator-position="none" :height="isMiddle?'560px':'360px'" style="height: 100%;">
					<el-carousel-item class="h-100 w-100" v-for="item in configs.class_images" :key="item">
						<div class=" d-flex  justify-content-center w-100">
							<img v-if="configs.class_images" class="cover-img w-100" style="object-fit: contain;" :src="$imgURL+item" alt="" />
						</div>

					</el-carousel-item>
				</el-carousel>

			</div>
		</div>



		<!-- 课程体系 -->
		<div class="subcontent mt-0 mt-sm-4" id='tixi'>
			<div class="home-title" v-if="configs">
				<div class="font-md text-center">{{ configs.system_name }}</div>
				<div class="font text-center font-line">{{ configs.system_eng }}</div>
				<div class="font text-center">{{ configs.system_desc }}</div>



			</div>

			<div class="d-flex  mt-sm-2 mt-0 mb-sm-4 mb-0 align-items-center  justify-content-center h-sm-5">
				<div class="container   my-2 mt-sm-4   justify-content-center d-flex flex-column   flex-sm-row">
					<div class="col-12    p-0   justify-content-sm-between justify-content-center  col-sm-2 d-flex flex-row flex-lg-column flex-wrap h-sm-5">
						<div class="pointer  w-100-tixi  text-center  font   p-tixi  py-lg-0-5" v-for="(v, i) in systems" :key="i"
						 @mouseleave="classMouseleave(i)" @mousemove="classMousemove(i)">
							<div :class="[isClassActiveIndex == i ? 'bg-secondary text-white' : 'bg-yellow']" class="bg-yellow py-0-5 py-md-1 px-0-5 px-0 w-100 font-weight-bold "
							 style="letter-spacing: 0.02rem;">
								<div class="text-over">{{ v.title }}</div>
							</div>
						</div>
					</div>
					<div class="col-12 p-0  mt-2 mt-sm-0  px-0-5 px-sm-0  pl-sm-1 pl-0  col-sm-10 tixi-samll h-sm-5">

						<div class="d-flex  align-items-center flex-row h-100  ">
							<div class="col-8 h-100  d-flex justify-content-center p-0 ">
								<img :src="$imgURL+ systems[isClassActiveIndex].image" class="w-100 h-100" :class="yixiImg?' animate__animated animate__fadeIn':''"
								 style="object-fit: cover;" alt="" @animationend='yixiImg=false'>
							</div>

							<div style="overflow: hidden;height: 120%; " :class="yixiImg?' animate__animated animate__fadeIn':''" class="w-100  bg-blue px-0-5 px-sm-1-5 py-0-5  py-sm-1 text-white pointer  tixi-rsmall d-flex flex-column">
								<div class="font-lmd text my-0-5 my-sm-1   ">{{systems[isClassActiveIndex].title.split('螺旋递进')[1]}}</div>
								<div class="font  " style="line-height: 2;overflow: auto;height:70%" v-html="systems[isClassActiveIndex].content"></div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<!-- <div class="d-flex flex-row justify-content-center">
				<div class="class-content  f-b-0" v-if="systems.length>0">
					<div class="d-flex align-items-center">
						<ul class="left mr-2">
							<li :class="[isClassActiveIndex == i ? 'class-active' : '']" class="pointer" v-for="(v, i) in systems" :key="i"
							 @mouseleave="classMouseleave(i)" @mousemove="classMousemove(i)">
								{{ v.title }}
							</li>
						</ul>
					</div>


					<div class="right f-0-c ">
						<div class="img-wrap">
							<img v-for="(v, i) in systems" :key="i" :class="[isClassActiveIndex == i ? 'opacity-1' : '']" class="img" :src=" $imgURL+v.image"
							 alt="" srcset="" />
						</div>

						<div class="desc font px-1 py-3 text-white pointer">

							<div v-html="systems[isClassActiveIndex].title" class="font-lmd text my-1"></div>
							<div class="text text-white font" style="line-height: 2;" v-html="systems[isClassActiveIndex].content"></div>
						</div>
					</div>
				</div>
			</div> -->


		</div>




		<!-- <footers id="footer" /> -->
	</div>
</template>


<script>
	export default {
		data() {
			return {
				yixiImg: false,
				isClassActiveIndex: 0,
				pageBanner: [],
				configs: "", //module 标题文案
				overviews: [], //九大课程
				systems: [], //五大进阶
				windowWidth: '',
				isSmall: '',
				isMiddle: '',
			};
		},
		created() {

			this.curriculums();
			this.getPageBanner();
		},
		mounted() {
			let that = this
			that.isSmall = document.body.clientWidth < 567 ? true : false
			that.isMiddle = document.body.clientWidth <= 1024 && document.body.clientWidth > 567  ? true : false
			let type = this.$route.query.type
			this.scrollView(type)
			this.windowWidth = window.innerWidth
			window.onresize = () => {
				if (!this.timer) { // 使用节流机制，降低函数被触发的频率
					this.timer = true;
					let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
					setTimeout(function() {
						that.searchFormWidth();
						that.timer = false;
						that.isSmall = document.body.clientWidth < 567 ? true : false
						that.isMiddle =document.body.clientWidth <= 1024 && document.body.clientWidth > 567 ? true : false
					}, 400)
				}
			}

		},
		methods: {
			searchFormWidth() {
				let w = window.innerWidth;
				console.log(w);
				this.windowWidth = w
				// if (w <= 1280) {
				// 	this.searchWidth = 280
				// } else if (w < 1920) {
				// 	this.searchWidth = ((w - 1280) * 80) / 640 + 280
				// } else {
				// 	this.searchWidth = 360
				// }
			},
			scrollView(type) {
				if (type == 1) {
					setTimeout(() => {
						document.getElementById('tixi').scrollIntoView()
					}, 500)
				} else if (type == 2) {
					setTimeout(() => {
						document.getElementById('yunduan').scrollIntoView()
					}, 500)
				}
			},
			// 课程部分
			classMousemove(i) {
				this.isClassActiveIndex = i;
			},

			classMouseleave(i) {},
			curriculums() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "api/Curriculums/index",
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}
					this.configs = res.data.data.configs;
					this.classs = res.data.data.classs;
					this.overviews = res.data.data.overviews;
					this.systems = res.data.data.systems;
				});
			},
			getPageBanner() {
				var params = new URLSearchParams();
				params.append("key", "banner_curriculum");
				this.$axios({
					method: "get",
					url: this.$apiURL + "api/config/config?" + params,
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						this.pageBanner = res.data.data;
					}
				});
			},
		},
	};
</script>
<style scoped>
	.w-100px {
		width: 100px;
	}

	.border-kecheng {

		border: 5px solid #EEEEEC;
	}

	.bg-yellow1 {
		background-color: #FFC421 !important;
	}

	.bg-kecheng {
		background-color: #EEEEEE;
	}

	.kecheng-p {
		padding: 0.04rem;
	}

	.rounded-ssm {
		border-radius: 10px;
		overflow: hidden;
	}

	.obj-cover {
		object-fit: cover;
	}

	.w-600 {
		width: 550px;
	}

	.b0t-t2 {
		font-size: 0.36rem;
		font-weight: 500;
		color: #c8c8c8;
	}

	.round {
		border-radius: 2rem;
	}

	.h-8 {
		height: 8rem;
	}

	@media (max-width:960px) {
		

		.w-600 {
			width: auto !important;
		}
		
		.h-8 {
			height: auto;
		}
	}

	@media (max-width:576px) {
		.w-100px {
			width: auto !important;
		}

		.w-600 {
			width: auto !important;
		}

		.el-carousel__container {
			height: 400px !important;
		}

		.h-8 {
			height: auto;
		}
	}
</style>
<style lang="scss" scoped>
	@media (max-width:576px) {

		.tixi-rsmall {
			height: 2.5rem;
		}

		.tixi-samll {
			height: 2rem;
		}

		.tixi-nsm1 {
			display: none !important;
		}

		.tixi-sm1 {
			display: block !important;
		}

		.w-100-tixi {
			width: auto;
		}

		.p-tixi {
			padding: 0.04rem 0.08rem;
		}

		.cover {


			.left-wrap {

				h5 {
					letter-spacing: 0.1rem !important;

					font-size: 0.14rem !important;
				}

				h3 {
					font-size: 0.56rem !important;
				}

				h2 {
					font-size: 1.1rem !important;
				}

				h4 {
					font-size: 0.42rem !important;
				}


			}
		}
	}

	.class {
		padding: 1.21rem 2.9rem 2.37rem 2.9rem;
		background: url(../assets/img/home-bg1.png) no-repeat;
		background-size: 100% 100%;

		.class-content {
			margin-top: 0.87rem;

			.left {
				li {
					width: 2.5rem;
					height: 0.8rem;
					font-size: 0.2rem;
					text-align: center;
					line-height: 0.8rem;
					background: #ffe000;

					// margin-bottom: 0.74rem;
					margin: 0.5rem 0;

					&:last-child(1) {
						margin-bottom: 0;
					}

					&.class-active {
						background: #a0a0a0 !important;
						color: #fff !important;
					}
				}
			}

			.right {
				.img-wrap {
					width: 8rem;
					height: 6rem;
					//background: #d8d8d8;
					position: relative;

					.opacity-1 {
						opacity: 1 !important;
					}

					.img {
						width: 100%;
						position: absolute;
						height: 100%;
						opacity: 0;
						transition: 1s all;
					}
				}

				.text {
					transition: 1s all;
					// opacity: 0;
				}

				.desc {

					width: 4rem;
					height: 7.5rem;

					background: #a2dffc;
				}
			}
		}
	}



	// 公共部分
	.subcontent {
		width: 100%;
		box-sizing: border-box;
	}

	.home-title {
		h1 {
			font-size: 0.3rem;
			margin-bottom: 0.13rem;
			text-align: center;
		}

		h2 {
			font-size: 0.14rem;
			text-align: center;

			margin-bottom: 0.13rem;
		}

		h3 {
			font-size: 0.15rem;
			text-align: center;
		}
	}



	#recommend {
		.banner-img {
			width: 100%;
			// height: auto;
			min-height: 1rem;
			object-fit: cover;
		}

		.rec-wrap {
			// width: 1500px;
			// padding: 0.63rem 0;
			box-sizing: border-box;

			.home-title {
				// margin-bottom: 0.73rem;
			}

			.content {
				.item {
					// width: 100%;

					&:nth-child(odd) .top-wrap {}

					// 偶数
					&:nth-child(even) .top-wrap {
						flex-direction: row-reverse;
					}

					// 奇数

					&:nth-child(odd) .top-wrap .top {
						background: #9fd9f6;
						transform: translate(0.62rem, 50%);

						color: #fff;
					}

					// 偶数
					&:nth-child(even) .top-wrap .top {
						background: #fdd23e;
						transform: translate(-0.62rem, 50%);

						color: #333;
					}

					&:nth-child(odd) .bottom {}

					// 偶数
					&:nth-child(even) .bottom {
						flex-direction: row-reverse;
					}

					.top-wrap {
						width: 100%;
						// 奇数
						display: flex;
						font-size: 0.2rem;

						.top {
							// padding: 0 0.37rem;
							// height: 0.7rem;
							border-radius: 0.78rem;
							// line-height: 0.7rem;
							letter-spacing: 0.04rem;

							font-size: 0.22rem;
							font-weight: 600;
						}
					}

					.bottom {
						letter-spacing: 0.03rem;
						font-size: 0.18rem;
						width: 100%;
						// height: 1.2rem;
						background: #f6f0f2;
						border-radius: 0.78rem;

						// 奇数
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 0.20rem;
						padding: 0 0.56rem;
						box-sizing: border-box;

						.b0t-t1 {
							width: 10rem;
						}

						.b0t-t2 {
							font-size: 0.36rem;
							font-weight: 500;
							color: #c8c8c8;
						}
					}
				}
			}
		}

		.cover {
			width: 100%;
			// height: 8.4rem;
			color: #fff;
			text-align: center;

			.cover-img {
				width: 100%;
				// width: calc(100% - 6.85rem);
				height: 100%;
				object-fit: cover;
			}

			.left-wrap {
				// width: 6.85rem;

				background: #fdd23e;
				height: 100%;
				// padding-top: 1.59rem ;
				text-align: center;
				box-sizing: border-box;

				h5 {
					letter-spacing: 0.12rem;

					font-size: 0.18rem;
				}

				h3 {
					// letter-spacing: 0.06rem;
					// margin-top: 0.08rem;
					font-weight: bold;
					font-size: 0.73rem;
				}

				h2 {
					// letter-spacing: 0.13rem;
					font-weight: bolder;
					margin-top: -0.3rem;
					font-size: 1.48rem;
				}

				h4 {
					letter-spacing: 0.04rem;
					// margin-top: 1px;
					font-size: 0.56rem;

					margin-bottom: 0.39rem;
				}

				.desc {
					font-size: 0.18rem;
					text-align: left;

					letter-spacing: 0.04rem;
					line-height: 1.6;
				}
			}
		}

		.home-title {
			h1 {
				font-size: 0.3rem;
				margin-bottom: 0.13rem;
				text-align: center;
			}

			h2 {
				font-size: 0.14rem;
				text-align: center;

				margin-bottom: 0.13rem;
			}

			h3 {
				font-size: 0.15rem;
				text-align: center;
			}
		}

		.img {
			object-fit: cover
		}
	}
</style>
